import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { AlternativeNavigation, SEO } from "components";
import { Footer, Navbar, Top } from "views";
import { AboutLandings, Bundles, Contact } from "views/Sections";
import { findNodeBy } from "utils/breakDownAllNodes";
import "utils/fixFontAwesome";

import "../../style/main.scss";
import VideoLanding from "content/assets/videos/paint-roller-720p.webm";
import VideoThumb from "content/assets/videos/paint-roller-720p-thumbnail-0001.webp";

export const query = graphql`
  query bundleQuery($langKey: String!) {
    site {
      meta: siteMetadata {
        keywords
        description
      }
    }
    content: allMarkdownRemark(filter: { fields: { langKey: { eq: $langKey } } }) {
      nodes {
        i18n: frontmatter {
          ...NavbarMarkdownRemarkFragment
          ...LandingsNavigationMarkdownRemarkFragment
          ...TopMarkdownRemarkFragment
          ...BundleDetailsMarkdownRemarkFragment
          ...AboutMarkdownRemarkFragment
          ...ContactMarkdownRemarkFragment
          ...CallbackMarkdownRemarkFragment
          ...SendmailMarkdownRemarkFragment
          ...FooterMarkdownRemarkFragment
          ...ContactUsMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const Bundle = ({ data, pageContext }) => {
  const {
    site: {
      meta: { keywords, description },
    },
    content: { nodes },
  } = data;

  const topNode = findNodeBy(nodes, /bundletop\./i);
  const navBarNode = findNodeBy(nodes, /navbar\./i);
  const bundleNode = findNodeBy(nodes, /bundle\./i);
  const aboutNode = findNodeBy(nodes, /about\./i);
  const contactNode = findNodeBy(nodes, /contact\./i);
  const footerNode = findNodeBy(nodes, /footer\./i);
  const sitemapNode = findNodeBy(nodes, /sitemap\./i);
  const callbackNode = findNodeBy(nodes, /callback\./i);
  const contactUsNode = findNodeBy(nodes, /contactus\./i);

  const seo = { lang: pageContext.langKey, keywords, description };

  return (
    <>
      <SEO title={topNode.i18n.header} {...seo} />
      <Navbar frontmatter={navBarNode.i18n} langKey={pageContext.langKey}>
        <AlternativeNavigation context={pageContext} frontmatter={sitemapNode.i18n} />
      </Navbar>
      <Top frontmatter={topNode.i18n} video={VideoLanding} poster={VideoThumb} />
      <Bundles frontmatter={bundleNode.i18n} className="bg-light" />
      <AboutLandings frontmatter={aboutNode.i18n} className="bg-light" />
      <Contact
        frontmatter={contactNode.i18n}
        callbackNode={callbackNode.i18n}
        contactUsNode={contactUsNode.i18n}
        className="bg-dark"
      />
      <Footer
        frontmatter={footerNode.i18n}
        navigationNode={sitemapNode.i18n}
        contactUsNode={contactUsNode.i18n}
      />
    </>
  );
};

Bundle.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

Bundle.defaultProps = {
  pageContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default Bundle;
